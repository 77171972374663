import React, {
  useContext, useRef, useState,
} from 'react';
import {
  Box, Image, NativeBaseProvider, View,
  useToast,
} from 'native-base';
import {
  Avatar, IconButton, Banner, Card, ProgressBar, Text,
  Chip,
} from 'react-native-paper';
import { useMutation, useQuery } from '@apollo/client';
import Flag from 'react-native-flags';
import { useNavigation } from '@react-navigation/native';
import { isFuture } from 'date-fns';
import { TouchableOpacity } from 'react-native';
import { styles, ids } from './Common.style';
import { FindManyEvents } from '../gql/event/query';
import {
  dateTimeOptions, ROLE_NAMES, SCREEN_NAMES,
} from '../utils/constant';
import { FindManyAnnouncements } from '../gql/announcement/query';
import { AcknowledgeAnnouncement } from '../gql/announcement/mutation';
import { ISOCODE } from '../utils/isocode';
import { JoinEvent, LeaveEvent } from '../gql/event/mutation';
import { AuthContext } from '../context/AuthContext';
import { computeCategorie } from '../utils/utils';
import { FindOneUserQuery } from '../gql/user/query';
import { ModalContext } from '../context/ModalContext';
import InformationModal from '../modals/Information/InformationModal';
import PlayersOnEventModal from '../modals/ManageEvent/DisplayPlayersOnEventModal';
import PictureModal from '../modals/Picture/PictureModal';

function Dashboard() {
  const { authState } = useContext(AuthContext);
  const toast = useToast();
  const navigation = useNavigation();

  const refEventId = useRef(undefined);
  const { data, loading } = useQuery(FindManyEvents);
  const {
    data: announcementData,
    loading: announcementLoading,
    error: announcementError,
  } = useQuery(
    FindManyAnnouncements,
    {
      variables: {
        endDate: today,
      },
    },
  );
  const [acknownledgeAnnouncement] = useMutation(AcknowledgeAnnouncement);
  const [joinEvent] = useMutation(JoinEvent);
  const [leaveEvent] = useMutation(LeaveEvent);

  const {
    displayInformationModal, toggleInformationModal,
    displayPlayersOnEventModal, togglePlayersOnEventModal,
    displayPictureModal, togglePictureModal,
    displayFiltersModal, toggleFiltersModal,
  } = useContext(ModalContext);
  const {
    data: oneUserQuery,
    loading: oneUserQueryLoading,
    error: oneUserQueryError,
    refetch,
  } = useQuery(FindOneUserQuery, {
    variables: {
      email: authState.email,
    },
  });

  const [today, setToday] = useState(new Date());

  // Computed values
  const user = oneUserQuery?.findOneUser;
  const isSuperAdmin = authState.role === ROLE_NAMES.SUPERADMIN;
  const isAdmin = [ROLE_NAMES.COACH, ROLE_NAMES.SUPERADMIN].includes(authState.role);
  const userCategories = computeCategorie(new Date(user?.profile?.dateOfBirth));

  const handlePlayersOnEvent = (_eventId, action) => {
    if (isAdmin) {
      refEventId.current = Number(_eventId);
      togglePlayersOnEventModal({ action });
    }
  };

  return (
    <View style={styles.container}>
      {announcementData?.findManyAnnouncements?.map((a) => (
        <Banner
          visible
          actions={[
            {
              label: 'J\'ai lu',
              onPress: () => {
                acknownledgeAnnouncement(
                  {
                    variables: { acknowledgeAnnouncementId: a.announcement.id, hasBeenRead: true },
                    refetchQueries: [
                      FindManyAnnouncements, // DocumentNode object parsed with gql
                      'findManyAnnouncements', // Query name
                    ],
                  },

                );
              },
            },
          ]}
          icon={() => (
            <Avatar.Image
              size={50}
              source={{ uri: a.announcement.author.profile.picture ?? '', width: '200px' }}
            />
          )}
        >
          <Text variant="labelMedium">{a.announcement.title}</Text>
          <br />
          <Text variant="labelSmall">{a.announcement.message}</Text>
        </Banner>
      ))}
      {loading && <ProgressBar />}
      <View style={styles.middle}>
        <Box style={styles.logoStyle} dataSet={{ media: ids.logoStyle }}>
          <Image
            style={styles.responsiveImage}
            dataSet={{ media: ids.responsiveImage }}
            roundedTop="lg"
            source={require('../assets/logo.png')}
            alt="image"
          />
        </Box>
      </View>
      <View style={styles.right}>
        <IconButton icon="sort" onPress={toggleFiltersModal} />
      </View>
      <View>
        {/* {displayFiltersModal.display && <ManageFiltersModal />} */}
        {displayPictureModal.display && <PictureModal />}
        {displayInformationModal.display && <InformationModal />}
        {displayPlayersOnEventModal.display && <PlayersOnEventModal eventId={refEventId.current} /> }
        {!displayPictureModal.display && !displayInformationModal.display && !displayPlayersOnEventModal.display && data?.findManyEvents?.map((d) => {
          const splittedLocation = d.location.split(',');
          const country = splittedLocation[splittedLocation.length - 1].trim();

          const hasAlreadyJoined = d.players?.map((p) => p.player.id).includes(user?.id);
          const canJoin = !hasAlreadyJoined
                    && user?.status === 'COMPETITOR'
                    && userCategories.some((c) => d.categories.includes(c))
                    && isFuture(new Date(d.registerEndAt));
          return (
            <Card key={d.name} style={{ padding: 2 }}>
              <Card.Title
                key={`${d.name}-title`}
                title={d.name}
                subtitle={`Date: ${new Date(d.startAt).toLocaleDateString(
                  'fr-FR',
                  dateTimeOptions,
                )}\nLieu: ${d.location}\nCatégories: ${d.categories?.join(',')}`}
                subtitleNumberOfLines={5}
                left={() => (
                  <TouchableOpacity onPress={() => togglePictureModal({ eventId: d.id, isInfoMedia: false })}>
                    <Image
                      style={{ height: '50px' }}
                      source={{ uri: d.mediaThumbnail }}
                    />
                  </TouchableOpacity>
                )}
                right={(props) => (
                  <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => togglePictureModal({ eventId: d.id, isInfoMedia: true })}>
                      <Image
                        {...props}
                        style={{ height: '50px', width: '50px' }}
                        source={{ uri: d.infoMediaThumbnail }}
                      />
                    </TouchableOpacity>
                    <Flag
                      {...props}
                      type="flat"
                      code={Object.entries(ISOCODE).find((e) => e[1] === country)?.[0]}
                      size={32}
                    />
                  </View>
                )}
              />
              <Card.Actions key={`${d.name}-actions`} style={{ gap: '5px' }}>
                <>
                  <Chip
                    disabled={d.players.filter((p) => p.isConfirmed).length === 0}
                    icon="karate"
                    onPress={() => handlePlayersOnEvent(d.id, 'REMOVE')}
                  >
                    {`${d.players.filter((p) => p.isConfirmed).length} ${d.players.length > 1 ? 'confirmés' : 'confirmé'}`}
                  </Chip>
                  <Chip
                    disabled={d.players.filter((p) => !p.isConfirmed).length === 0}
                    icon="progress-clock"
                    onPress={() => handlePlayersOnEvent(d.id, 'ADD')}
                  >
                    {`${d.players.filter((p) => !p.isConfirmed).length} en attente`}
                  </Chip>
                </>
                { hasAlreadyJoined
                  ? (
                    <Chip
                      key={`${d.name}-button`}
                      sx={{ marginRight: '5px' }}
                      mode="outlined"
                      onPress={async () => {
                        const status = await leaveEvent(
                          {
                            variables: { eventId: Number(d.id) },
                            refetchQueries: [
                              FindManyEvents, // DocumentNode object parsed with gql
                              'findManyEvents', // Query name
                            ],
                          },
                        );
                        toast.show(status.message);
                      }}
                    >
                      Se désinscrire
                    </Chip>
                  )
                  : (
                    <>
                      <Chip
                        key={`${d.name}-button`}
                        mode="outlined"
                        disabled={!canJoin}
                        onPress={async () => {
                          const status = await joinEvent(
                            {
                              variables: { joinEventId: Number(d.id) },
                              refetchQueries: [
                                FindManyEvents, // DocumentNode object parsed with gql
                                'findManyEvents', // Query name
                              ],
                            },
                          );
                          toast.show(status.message);
                        }}
                      >
                        S'inscrire
                      </Chip>
                      { user?.status !== 'COMPETITOR'
                            && (
                            <IconButton
                              icon="message-alert"
                              iconColor={user?.status === 'REVIEW_PENDING' ? 'orange' : 'red'}
                              onPress={() => toggleInformationModal({
                                title: 'Information importante',
                                message: user?.status === 'REVIEW_PENDING'
                                  ? 'Tous les documents sont présents, une notification a été addressée aux administrateurs afin de valider leurs conformités.'
                                  : `
Afin de s\'inscrire aux compétitions, il est nécessaire d\'ajouter l\'ensemble des documents requis depuis l\'onglet "Documents"
Un administrateur vérifiera ensuite que tous les documents sont conformes.
                  `,
                              })}
                            />
                            )}
                    </>
                  )}
                <IconButton
                  icon={isSuperAdmin ? 'application-edit' : 'eye'}
                  onPress={() => navigation.navigate(
                    { name: SCREEN_NAMES.EVENT, params: { eventId: d.id } },
                  )}
                />
              </Card.Actions>
            </Card>
          );
        })}
      </View>
    </View>
  );
}

export default function () {
  return (
    <NativeBaseProvider>
      <Dashboard />
    </NativeBaseProvider>
  );
}
